@import url('https://fonts.googleapis.com/css2?family=Cantarell:wght@400;700&display=swap');

body {
  background: #00b4db; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    127.6deg,
    #00b4db 97%,
    #0083b0 100%
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    127.6deg,
    #00b4db 97%,
    #0083b0 100%
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-family: 'Cantarell', sans-serif;
}
img {
  max-width: 100%;
}

.header-color {
  background: #00b4db; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    127.6deg,
    #00b4db 97%,
    #0083b0 100%
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    127.6deg,
    #00b4db 97%,
    #0083b0 100%
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.mdl-layout__drawer-button {
  color: #000 !important;
}
.logo-image {
  max-width: 100%;
  height: 50px;
}
@media screen and (max-width: 1024px) {
  #root .mdl-layout__header {
    display: block;
  }
  #root .mdl-layout__header .mdl-navigation {
    display: none;
  }
}
.landing-grid {
  background: #00b4db; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    127.6deg,
    #00b4db 97%,
    #0083b0 100%
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    127.6deg,
    #00b4db 97%,
    #0083b0 100%
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;
}
.cube {
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px #d7d4e4;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: cube 12s ease-in forwards infinite;
}
.cube:nth-child(2n) {
  border-color: #fff;
}
.cube:nth-child(2) {
  animation-delay: 2s;
  left: 25vw;
  top: 40vh;
}
.cube:nth-child(3) {
  animation-delay: 4s;
  left: 75vw;
  top: 50vh;
}
.cube:nth-child(4) {
  animation-delay: 6s;
  left: 90vw;
  top: 10vh;
}
.cube:nth-child(5) {
  animation-delay: 8s;
  left: 10vw;
  top: 85vh;
}
.cube:nth-child(6) {
  animation-delay: 10s;
  left: 50vw;
  top: 10vh;
}
@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}
.avatar-img {
  height: 250px;
  padding-top: 5em;
}

.banner-text {
  background-color: #051367;
  opacity: 0.8;
  width: 75%;
  margin: auto;
  border-radius: 30px;
  padding: 1px 10px 10px;
  box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.5);
}

.banner-text h1 {
  font-family: 'Cantarell', sans-serif;
  font-size: 64px;
  font-weight: bold;
  color: white;
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  font-size: 20px;
  padding: 1em;
  color: white;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.social-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}

.social-links i:hover {
  color: #00b4db;
}

.projects-grid {
  display: flex;
}

.container-about {
  background-color: rgb(42, 52, 164);
  border-radius: 15px;
  border-style: hidden;
  padding: 18px;
  text-align: center;
}

.container-about p {
  font-family: 'Slabo 27px', serif;
  font-size: 20px;
  padding-right: 30px;
  padding-left: 30px;
}

.container-about h1 {
  font-family: 'Ruda', sans-serif;
}

/* 
About me section here
*/

#about {
  /* background: #282828; */
  /* font-family: 'Comic Sans MS', cursive, sans-serif; */
  text-align: justify;
  overflow: hidden;
  padding-top: 98px;
  padding-bottom: 101px;
}

#about .triangle-right {
  margin-top: 95px;
  width: 0;
  height: 0;
  border-top: 259px solid transparent;
  border-left: 470px solid #d6c6b2;
  border-bottom: 259px solid transparent;
}

#about .triangle-right img {
  position: absolute;
  left: -1px;
  top: 81px;
  width: 74%;
}

.fa-play {
  font-size: 100px;
}

#about .profile-container {
  background: #051367;
  box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  padding: 25px;
}

#about .profile-container > p {
  font-size: 20px;
  line-height: 28px;
}
#about .profile-container > p:not(:last-child) {
  margin-bottom: 20px;
}

#about h2 {
  margin-bottom: 47px;
  margin-top: 12px;
}

#about .social-link-text {
  margin-top: 50px;
  margin-bottom: 25px;
}
#about .social-link-text .btn-contact--cta {
  display: inline-block;
  background: #051367;
  color: #fff;
  padding: 10px 15px;
  border-radius: 25px;
  font-weight: 700;
  font-size: 24px;
  font-family: 'Cantarell', sans-serif;

  transition: all 0.3s ease-in-out;
}

#about .social-link-text .btn-contact--cta:hover {
  background: #fff;
  color: #051367;
  text-decoration: none;
  box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.5);
}

#about .about-link {
  padding-left: 0px;
}

#about .about-link li {
  display: inline-block;
}

#about .about-link li a i {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  border: 1px solid #ffffff;
  margin-right: 10px;
  font-size: 22px;
  color: #ffffff;
  transition: all 0.3s;
}

#about .about-link li a i:hover {
  color: #051367;
  background: #ffffff;
  border-color: #ffffff;
}

#about .about-img {
  position: relative;
}

@media only screen and (min-width: 992px) {
  #about .about-img {
    margin-top: 10ch;
  }
}
@media only screen and (max-width: 991px) {
  #about .about-img {
    text-align: center;
    margin-bottom: 30px;
  }
  #about h2 {
    margin-bottom: 25px;
  }
}
#about .about-img .man {
  position: absolute;
  bottom: 161px;
  top: 12px;
  left: -2px;
}

.color-3 {
  color: #d6c6b2;
}
.text-dark {
  color: #000000;
}
.text-white {
  color: white;
}

p {
  margin-bottom: 0;
  line-height: 24px;
  color: #d6c6b2;
}

.btn-danger {
  border-radius: 0;
  padding: 10px 20px;
}

.btn-danger a {
  color: aliceblue;
}
.projects-grid .mdl-button.mdl-button--colored {
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  [class*='landing-grid'] {
    position: relative;
    width: 100%;
    height: 100%;
  }

  [class*='avatar-img'] {
    height: 250px;
    padding-top: 5em;
  }

  .banner-text h1 {
    font-size: 30px;
  }

  .banner-text p {
    font-size: 15px;
    padding: 1em;
    color: white;
    font-weight: bold;
  }

  #about .profile-container > p {
    font-size: 15px;
    line-height: 24px;
  }

  .social-links {
    display: flex;
    justify-content: center;
    width: 50%;
    margin: auto;
  }

  .social-links i {
    color: white;
    font-size: 4em;
    padding: 4px;
  }

  .projects-grid {
    display: flex;
    flex-direction: column;
    height: 300px;
  }

  .mdl-shadow--16dp {
    width: 60% !important;
    margin-bottom: 40px !important;
  }

  .shape {
    width: 50%;
    border-radius: 50%;
  }

  .mdl-card__supporting-text {
    display: none;
  }
  /* 
  .mdl-card__title {
    height: 300;
  } */
}
